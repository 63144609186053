import React, { useState } from 'react';
import { useNavigate, useMatch, useLocation } from 'react-router';
import { Link } from 'react-router-dom'

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav as Navy, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, Row } from 'reactstrap';
import { Logo } from './components/common';
import { useUserContext, useClient } from './hooks';
import { NotificationManager } from 'react-notifications';
import classNames from 'classnames';

const isSidebar = false;
const NavLink = ({
	to,
	mobile,
	test,
	menu,
	className: classNameProp,
	children,
	end = false,
	...props
}) => {
	let match = useMatch({
		path: to,
		end,
	});
	if (!to) match = null;

	const [isOpen, setIsOpen] = useState(false);

	if (test !== undefined && !test) return null;

	const className = classNames(
		!isSidebar ? 'nav-link navigation-item' : 'nav-link sidebar-navigation-item',
		match ? "bg-therapygrey nav-font rounded-lg" : "text-white",
	);

	if (menu) {
		return (
			<Dropdown isOpen={isOpen} toggle={() => setIsOpen((v) => !v)}>
				<DropdownToggle
					color="link"
					className={classNames(
						className,
						"no-underline",
						mobile ? "text-left w-full" : ""
					)}
				>
					{children}
				</DropdownToggle>
				{menu}
			</Dropdown>
		);
	}

	return (
		<NavItem>
			<Link className={className} to={to} {...props}>
				{children}
			</Link>
		</NavItem>
	);
};

export const Nav = ({ children }) => {

	const { user, logout, refreshUser } = useUserContext();
	const match = useLocation();

	const navigate = useNavigate();

	const isSidebar = false;

	const onLogout = () => {
		logout();
		navigate('/login');
	};

	const contactUs = () => { window.open('https://www.self-therapist.com/contact', '_blank'); };
	const aboutUs = () => { window.open('https://www.self-therapist.com/about', '_blank'); };
	const clientFeedback = () => { window.open('https://forms.gle/roDiCP3BepNaCDcn7', '_blank'); };
	const therapistFeedback = () => { window.open('https://forms.gle/EMfQHR9KYyfg8XgS8', '_blank'); };
	const crisisInformation = () => { window.open('https://www.self-therapist.com/crisis-information', '_blank'); };
	const mentalHealth = () => { window.open('https://self-therapist.co.uk/guides/25', '_blank'); };
	const consentAgreement = () => { window.open('https://www.self-therapist.com/study-consent', '_blank'); };
	const feedback = () => { window.open('https://www.self-therapist.com/feedback-forms', '_blank'); };
	const faqs = () => { window.open('https://self-therapist.com/faq', '_blank'); };
	const onboarding = () => {
		localStorage.setItem("completed_onboarding", 'true');
		navAndClose('/onboarding_instructions');
	};

	const [isOpen, setIsOpen] = useState(false);
	const client = useClient();

	function startTrial() {
		client.post("start_trial").then(e => {
			refreshUser();
			NotificationManager.success("You have successfully started your free trial.");
			localStorage.setItem("completed_onboarding", 'true');
			navigate('/onboarding_instructions');

		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not start free trial, please contact sue@suerenger.co.uk for help");
		});
	}
	// function resendVerification() {
	// 	client.post("reverify_email").then(e => {
	// 		refreshUser();
	// 		NotificationManager.success("Email sent!");

	// 	}).catch(exc => {
	// 		console.warn(exc);
	// 		NotificationManager.error("Could not send your email verification, please check it is correct in the My Account area.");
	// 	});
	// }
	function navAndClose(page) {
		navigate(page);
		closeMenu();
	}

	function closeMenu() {
		setIsOpen(false);
	}


	const navItems = (
		<>
			<NavLink to='/decision_tree_options'>
				Start
			</NavLink>
			<NavLink to='/super-admin' test={user.is_superuser}>
				Super Admin
			</NavLink>
			<NavLink to='/admin' test={user.is_organisation_admin}>
				Organisation Admin
			</NavLink>
			<NavLink to='/help'>
				Help
			</NavLink>
			<NavLink to='/about_us'>
				About Us
			</NavLink>
			<NavLink onClick={contactUs} to="#">
				Contact Us
			</NavLink>
			<NavLink onClick={crisisInformation} to="#">
				Crisis Information
			</NavLink>
			<NavLink onClick={consentAgreement} to="#">
				Study Consent
			</NavLink>
		</>
	);

	return (
		<div className="pb-2">
			{/* <Col style={{ width: '200px !important', height: '100%' }}> */}
			<Navbar className='nav-top-bar' color='custom' dark expand='lg'>
				{/* {!isSidebar && <NavbarBrand href='/'>Fraser Portraits</NavbarBrand>} */}
				<NavbarBrand className="cursor-pointer" onClick={() => navigate('/decision_tree_options')}>
					<Logo showName classes="img_logo" txtClasses="h4" onClick={() => navigate('/decision_tree_options')} />
				</NavbarBrand>

				<NavbarToggler
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				/>
				<Collapse isOpen={isOpen} navbar>
					<Navy className='ml-auto' navbar>
						<NavLink onClick={() => closeMenu()} to='/decision_tree_options'>
							Start
						</NavLink>
						<NavLink onClick={() => closeMenu()} to='/aims_list'>
							Notebook
						</NavLink>
						{user?.organisation != null && user?.organisation?.popups_enabled === false ?
							null
							:
							<NavLink onClick={() => closeMenu()} to='/guides'>
								Self-Therapy Guides
							</NavLink>
						}
						<NavLink onClick={() => closeMenu()} to='/super-admin' test={user.is_superuser}>
							Super Admin
						</NavLink>
						<NavLink onClick={() => closeMenu()} to='/admin' test={user.is_organisation_admin}>
							Organisation Admin
						</NavLink>
						<NavLink onClick={crisisInformation} to="#">
							Crisis Information
						</NavLink>
						<NavLink onClick={() => closeMenu()} to="/guides/25">
							Mental Health Helper's Course
						</NavLink>
						<NavLink onClick={feedback} to="#">
							Feedback
						</NavLink>
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle className={"text-white"} nav caret>
								About
							</DropdownToggle>
							<DropdownMenu end>
								<DropdownItem onClick={aboutUs}>About Us</DropdownItem>
								<DropdownItem onClick={contactUs}>Contact Us</DropdownItem>
								<DropdownItem onClick={() => navAndClose('/the_research')}>Academic Research</DropdownItem>
								{/* <DropdownItem onClick={clientFeedback}>User's Feedback</DropdownItem>
								<DropdownItem onClick={therapistFeedback}>Helper's Feedback</DropdownItem> */}
							</DropdownMenu>
						</UncontrolledDropdown>
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle className={"text-white"} nav caret>
								Help
							</DropdownToggle>
							<DropdownMenu end>
								{/* <DropdownItem onClick={onboarding}>Introduction</DropdownItem> */}
								<DropdownItem onClick={() => window.open('https://www.self-therapist.com/help', '_blank')}>User Guide</DropdownItem>
								{!!user.show_study_feedback && (<>
									<DropdownItem onClick={consentAgreement}>Study Consent</DropdownItem>
								</>)}
								<DropdownItem onClick={faqs}>Help/FAQs</DropdownItem>

							</DropdownMenu>
						</UncontrolledDropdown>

						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle className={"text-white"} nav caret>
								Account
							</DropdownToggle>
							<DropdownMenu end>
								<DropdownItem onClick={() => navAndClose('/my_account')}>My Account</DropdownItem>
								{!!user.can_access_subscriptions &&
									<DropdownItem onClick={() => navAndClose('/stripe-subscription')}>Billing</DropdownItem>
								}

								<DropdownItem onClick={onLogout}>Logout</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Navy>
				</Collapse>
			</Navbar>

			{/* {!user?.email_verified && (
				<div className="alert alert-warning text-center p-2 mb-0" role="alert">
				{"Please verify your email to ensure we have accurate records, it won't be shown to anyone at all. "}
					<u className="clicky" onClick={() => resendVerification()}>Resend verification email?</u>
				</div>
			)}  */}
			{!match.pathname.includes('/stripe-subscription') && (
				<>
					{!!(user?.subscription_status !== 'active' && user?.subscription_status !== 'active_trial' && user?.trial_ended && user?.trial_days_remaining === 0 && user?.subscription_status !== 'active_study' && !user?.is_superuser) && (
						<div className="alert alert-danger text-center p-2" role="alert">
							You don't currently have an active subscription. Please go <u className="clicky" onClick={() => navAndClose('/stripe-subscription')}>here</u> to subscribe.
						</div>
					)}
					{user?.trial_days_remaining > 0 && (
						<div className="alert alert-warning text-center p-2" role="alert">
							You have {user?.trial_days_remaining} days remaining of your trial. Click <u className="clicky" onClick={() => navAndClose('/stripe-subscription')}>here</u> to subscribe.

						</div>
					)}
					{user?.trial_ended === null && user?.subscription_status !== 'active_study' && !user?.is_superuser && (
						<div className="alert alert-info text-center p-2" role="alert">
							You have a free trial available. Click <u className="clicky" onClick={() => startTrial()}>here</u> to start.
						</div>
					)}
					{user?.subscription_status === 'expired_study' && (
						<div className="alert alert-info text-center p-2" role="alert">
							We're sorry, the study you were invited to has expired. Would you mind completing the User's Feedback form using the following <u className="clicky" onClick={() => clientFeedback()}>link</u>? If you want to continue as a self-paid user click <u className="clicky" onClick={() => startTrial()}>here</u> to subscribe.
						</div>
					)}
				</>
			)}
			{!isSidebar ? (
				children
			) : (
				<Row style={{ marginRight: '0px', marginLeft: '0px', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
					<Navbar className='nav-sidebar' color='custom' dark expand='md' style={{ display: 'block' }}>
						<Navy vertical className='ml' navbar>
							{navItems}
						</Navy>
					</Navbar>
					<div className='col'>{children}</div>
				</Row>
			)}
			<Row className="fixed-footer d-flex justify-content-center">
				<p className="mx-2">
					Copyright {" "}
					<a href={'https://www.self-therapist.com'} target="_blank" rel="noopener noreferrer">
						Self-Therapist Ltd
					</a>
				</p>
				<p className="mx-2">
					<a href={'https://www.self-therapist.com/app-terms'} target="_blank" rel="noopener noreferrer">
						Terms of Use |
					</a>
					<a href={'https://www.self-therapist.com/app-privacy'} target="_blank" rel="noopener noreferrer">
						{" "}Privacy Policy
					</a>
				</p>
			</Row>
		</div>
	);
};

export default Nav;
